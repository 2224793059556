import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import ThankYouWrapper from "../components/ThankYouWrapper";
import CardBlog from "../components/CardBlog";

import IconDribbble from "../images/icons/social/dribbble-white.svg";
import IconBehance from "../images/icons/social/behance-white.svg";
import IconLinkedIn from "../images/icons/social/linkedin-white.svg";

const ThankYouPage3 = ({ data, location: { pathname } }) => {
  const latestArticle = data.recentArticles?.edges
    ? data.recentArticles?.edges[0]?.node
    : undefined;

  return (
    <Layout
      hideFooter
      footerBgClass="bg-white"
      meta={{
        seo: {
          title: "Thank you for applying!",
        },
        path: pathname,
      }}
    >
      <div className="thank-you-page">
        <ThankYouWrapper
          title="Thank you!"
          description="Your application has been successfully submitted and we will get back to you soon."
          middleBlock={
            <div className="pt-2 middle-block">
              <p className="lead mt-3">
                <strong> Follow us on </strong>
              </p>
            </div>
          }
          socialLinksBlock={
            <div className="social-buttons-container">
              <a
                href="https://dribbble.com/adamfardstudio"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-3 social-button dribble"
              >
                <IconDribbble className="mr-2" />
                <span className="text-white">Dribbble</span>
              </a>
              <a
                href="https://www.behance.net/AdamFardStudio"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-3 social-button behance"
              >
                <IconBehance className="mr-2" />
                <span className="text-white">Behance</span>
              </a>
              <a
                href="https://www.linkedin.com/company/adam-fard/"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-3 social-button linkdin"
              >
                <IconLinkedIn className="mr-2" />
                <span className="text-white">LinkedIn</span>
              </a>
            </div>
          }
          article={<CardBlog key={latestArticle.id} cardData={latestArticle} />}
        />
      </div>
    </Layout>
  );
};

export default ThankYouPage3;

export const query = graphql`
  query ThankYouApplicationQuery {
    recentArticles: allDatoCmsArticle(
      limit: 1
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          cardDescription
          cardImage {
            gatsbyImageData(width: 1024, layout: CONSTRAINED)
          }
          category {
            name
          }
          id
          slug
        }
      }
    }
  }
`;
